html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ’’;
  content: none;
}
/* remember to define visible focus styles! 
:focus{
    outline:?????;
} */
/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
html,
body {
  background: #fdffff;
  font: 18px/1.4 Avenir, -apple-system, BlinkMacSystemFont, sans-serif;
  color: #2a2a2a;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}
body {
  display: table;
}
a {
  color: #3498db;
  text-decoration: none;
}
a:hover {
  color: #2980b9;
}
a:active {
  color: #3498db;
}
strong {
  font-weight: bold;
}
#container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  padding: 20px;
}
#container > div {
  width: 880px;
  margin: 0 auto;
  min-height: 254px;
  display: table;
}
#container > div img {
  -webkit-border-radius: 127px;
  -moz-border-radius: 127px;
  border-radius: 127px;
  position: relative;
  width: 254px;
  display: block;
  opacity: 0;
  float: left;
}
.ready #container > div img {
  -webkit-animation: fadein 0.3s ease-out forwards;
  -moz-animation: fadein 0.3s ease-out forwards;
  -ms-animation: fadein 0.3s ease-out forwards;
  -o-animation: fadein 0.3s ease-out forwards;
  animation: fadein 0.3s ease-out forwards;
}
#container > div div {
  width: 580px;
  display: table-cell;
  vertical-align: middle;
}
#container > div h1 {
  position: relative;
  font-size: 30px;
  opacity: 0;
}
.ready #container > div h1 {
  -webkit-animation: fadein 0.3s ease-out 0.7s forwards;
  -moz-animation: fadein 0.3s ease-out 0.7s forwards;
  -ms-animation: fadein 0.3s ease-out 0.7s forwards;
  -o-animation: fadein 0.3s ease-out 0.7s forwards;
  animation: fadein 0.3s ease-out 0.7s forwards;
}
#container > div p#intro {
  position: relative;
  margin: 20px 0;
  font-size: 22px;
  opacity: 0;
}
.ready #container > div p#intro {
  -webkit-animation: fadein 0.3s ease-out 0.85s forwards;
  -moz-animation: fadein 0.3s ease-out 0.85s forwards;
  -ms-animation: fadein 0.3s ease-out 0.85s forwards;
  -o-animation: fadein 0.3s ease-out 0.85s forwards;
  animation: fadein 0.3s ease-out 0.85s forwards;
}
#container > div p#actions {
  position: relative;
  opacity: 0;
}
#container > div p#actions a {
  font-size: 16px;
}
#container > div p#actions .sep {
  margin: 0 1.5em 0 0;
  color: rgba(42, 42, 42, 0.5);
}
#container > div p#actions iframe {
  position: relative !important;
  top: 8px;
  margin-right: 3px;
}
.ready #container > div p#actions {
  -webkit-animation: fadein 0.3s ease-out 1s forwards;
  -moz-animation: fadein 0.3s ease-out 1s forwards;
  -ms-animation: fadein 0.3s ease-out 1s forwards;
  -o-animation: fadein 0.3s ease-out 1s forwards;
  animation: fadein 0.3s ease-out 1s forwards;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
@-ms-keyframes fadein {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
/*------------------------------------*\
    $RESPONSIVE
\*------------------------------------*/
@media only screen and (max-width: 880px) {
  #container > div {
    width: 100%;
    text-align: center;
  }
  #container > div img {
    float: none;
    margin: 0 auto 20px auto;
    width: 200px;
  }
  #container > div div {
    display: block;
    width: 100%;
  }
  #container > div p {
    font-size: 17px;
  }
}

/*# sourceMappingURL=index.34927db4.css.map */
