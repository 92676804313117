@import "./reset.less";
@import "./mixins.less";

html, body {
    background: #fdffff;
    font: 18px/1.4 Avenir, -apple-system, BlinkMacSystemFont, sans-serif;
    color: #2a2a2a;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
}

    body {
        display: table;
    }

a {
    color: #3498db;
    text-decoration: none;

    &:hover {
        color: #2980b9;
    }

    &:active {
        color: #3498db;
    }
}

strong {
    font-weight: bold;
}

#container {
    @fade-delay: .7s;
    @fade-duration: .3s;

    .box-sizing(border-box);
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding: 20px;

    > div {
        width: 880px;
        margin: 0 auto;
        min-height: 254px;
        display: table;

        img {
            .border-radius((254px / 2));
            position: relative;
            width: 254px;
            display: block;
            opacity: 0;
            float: left;

            .ready & {
                .animation(fadein @fade-duration ease-out forwards);
            }
        }

        div {
            width: 580px;
            display: table-cell;
            vertical-align: middle;
        }

        h1 {
            position: relative;
            font-size: 30px;
            opacity: 0;

            .ready & {
                .animation(fadein @fade-duration ease-out @fade-delay forwards);
            }
        }

        p#intro {
            position: relative;
            margin: 20px 0;
            font-size: 22px;
            opacity: 0;

            .ready & {
                .animation(fadein @fade-duration ease-out (@fade-delay + .15s) forwards);
            }
        }

        p#actions {
            position: relative;
            opacity: 0;
            
            a {
                font-size: 16px;
            }

            .sep {
                margin: 0 1.5em 0 0;
                color: fade(#2a2a2a, 50%);
            }

            iframe {
                position: relative !important;
                top: 8px;
                margin-right: 3px;
            }

            .ready & {
                .animation(fadein @fade-duration ease-out (@fade-delay + .3s) forwards);
            }
        }
    }
}

.fadein-frames () {
    from { opacity: 0; top: -20px; }
    to { opacity: 1; top: 0; }
}
@-webkit-keyframes fadein { .fadein-frames; }
@-moz-keyframes fadein { .fadein-frames; }
@-ms-keyframes fadein { .fadein-frames; }
@-o-keyframes fadein { .fadein-frames; }
@keyframes fadein { .fadein-frames; }

/*------------------------------------*\
    $RESPONSIVE
\*------------------------------------*/

@media only screen and (max-width: 880px) {
    #container > div {
        width: 100%;
        text-align: center;

        img {
            float: none;
            margin: 0 auto 20px auto;
            width: 200px;
        }

        div {
            display: block;
            width: 100%;
        }

        p {
            font-size: 17px;
        }
    }
}
